<template>
  <n-form
    :label-placement="isMobile ? 'top' : 'left'"
    require-mark-placement="right-hanging"
    label-width="200px"
    label-align="left"
    :style="{
      maxWidth: '640px',
    }"
    :model="model"
    ref="form"
    ><n-steps vertical :current="current" :status="currentStatus" class="mt-2 ml-2">
      <n-step :title="t('rent.checklist.steps.one.title')" class="mr-4"
        ><div class="n-step-description pt-4" v-if="current === 1">
          <FileUploader
            v-if="rent"
            v-model="model.files"
            :bucket="filesBucket"
            v-model:loading="isLoading"
            :filesTypeAllowed="[
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/heic',
              'video/mp4',
              'video/quicktime',
            ]"
          /><navigations-button-section
            :current="current"
            :step="1"
            isInitStep
            :totalSteps="totalSteps"
            @clickNext="next"
            :loading="isLoading"
            :canContinue="model.files.length > 0"
          /></div
      ></n-step>
      <n-step :title="t('rent.checklist.steps.two.title')" class="mr-4"
        ><div class="n-step-description pt-4" v-if="current === 2">
          <Checklist
            v-model="model.checklist"
            :checklistType="checklistType"
          /><navigations-button-section
            :current="current"
            :step="2"
            :totalSteps="totalSteps"
            @clickNext="next"
            @clickBack="prev"
          /></div
      ></n-step>
      <n-step :title="t('rent.checklist.steps.three.title')"
        ><div class="n-step-description pt-4" v-if="current === 3">
          <ChecklistResume
            v-model="model.checklist"
            :checklistType="checklistType"
            :bucket="filesBucket"
            :ownerId="rent.publication.user"
          /><br /><navigations-button-section
            :current="current"
            :step="3"
            :totalSteps="totalSteps"
            @clickNext="next"
            @clickBack="prev"
            isFinalStep
          /></div></n-step></n-steps
  ></n-form>
</template>

<style lang="scss" scoped>
.n-step-description {
  margin: 0 auto;
  max-width: 600px;
}

.stepper {
  margin: 0 auto;
  max-width: 79.5%;
}

.n-step-description :deep(.n-form-item-label__asterisk) {
  visibility: hidden;
}

.n-step-description :deep(.n-dynamic-input .n-dynamic-input-item) {
  margin-bottom: unset;
}
:deep(.n-form-item .n-form-item-label) {
  margin-right: 5px;
}
</style>

<script>
import { defineComponent, ref, onMounted, computed, h } from "vue";
import { useDialog, useMessage, NSteps, NStep, NForm, NAlert } from "naive-ui";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import Checklist from "@/components/rent/Checklist/ChecklistForm.vue";
import ChecklistResume from "@/components/rent/Checklist/ChecklistResume.vue";
import { useRouter } from "vue-router";
import NavigationsButtonSection from "@/components/publication/maintainers/NavigationsButton.vue";
import FileUploader from "@/components/rent/Checklist/FileUploader.vue";
import { differenceInCalendarDays } from "date-fns";
import { formatDates, quantityDaysInRange } from "@/shared/utils";
import DelayedMessageConfirmation from "@/components/rent/Checklist/DelayedMessageConfirmation.vue";
import * as Big from "js-big-decimal";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "CheckListStepper",
  components: {
    Checklist,
    NForm,
    NSteps,
    NStep,
    NavigationsButtonSection,
    ChecklistResume,
    FileUploader,
  },
  mixins: [responsive],
  props: {
    checklistType: {
      type: String,
      required: true,
      validator(value) {
        return ["DELIVERY", "RETURN"].includes(value);
      },
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const dialog = useDialog();
    const message = useMessage();
    const rentRef = computed(() => store.state.maintainer_rents.detail);
    const currentRef = ref(1);
    const totalStepsRef = ref(3);
    const currentStatusRef = ref("process");
    const isLoadingRef = ref(false);
    const formRef = ref(null);
    const modelRef = ref({
      checklist: [],
      files: [],
    });
    const currentUser = store.getters["auth/user_id"];

    const daysExecuted = ref(0);
    const daysLeft = ref(0);
    const delayedDays = ref(0);
    const chargeExtraDays = ref(false);

    const initChecklist = () => {
      if (props.checklistType === "DELIVERY") {
        modelRef.value.checklist = rentRef.value.publication.checkListFields.map(
          (item) => {
            return {
              name: item.name,
              type: item.type,
              value: item.type === "BOOLEAN" ? false : null,
            };
          }
        );
      } else {
        //Se agrega de primero los campos que permitiran saber si el rent cierra o se va a arbitraje

        modelRef.value.checklist = [
          {
            ref: 1,
            name: t("rent.checklist.steps.one.returnRequiredQuestions.needChargeDamages"),
            type: "BOOLEAN",
            value: false,
            hidden: false,
          },
        ];
        modelRef.value.checklist.push({
          ref: 2,
          name: t("rent.checklist.steps.one.returnRequiredQuestions.detailsAboutDamages"),
          type: "STRING",
          value: null,
          hidden: computed(
            () =>
              !modelRef.value.checklist.find((checklistItem) => checklistItem.ref === 1)
                .value
          ),
        });

        //Se agregan el resto de los campos llenados inicialmente en el checklist de entrega
        modelRef.value.checklist = [
          ...modelRef.value.checklist,
          ...rentRef.value.deliveryCheckList.map((item) => {
            return {
              ref: null,
              name: item.name,
              type: item.type,
              value: item.type === "BOOLEAN" ? false : null,
            };
          }),
        ];
      }
    };

    const executePaymentReverse = async () => {
      await store.dispatch("maintainer_rents/revertRentPayment");
      await store.dispatch("notifications/FireNotification", {
        userId: rentRef.value.user,
        fromUserId: rentRef.value.publication.user,
        title: t("rent.checklist.notifications.paymentReverted.title"),
        message: t("rent.checklist.notifications.paymentReverted.content", {
          publicationTitle: rentRef.value.publication.title,
        }),
        avatar: {
          publicationID: rentRef.value.publication.id,
          publicationUser: rentRef.value.publication.user,
          defaultImage: rentRef.value.publication.orderPhotos[0],
        },
        targetRoute: {
          name: "view-rent-detail",
          params: [
            {
              key: "id",
              value: rentRef.value.id,
            },
          ],
        },
      });
    };

    const sendPaymentRequestForExtraDays = async () => {
      const messageNotification = t(
        "rent.checklist.notifications.paymentRequired.content",
        {
          qtyDays: delayedDays.value,
          daysLabel: t("commons.labels.day", delayedDays.value).toLowerCase(),
          publicationTitle: rentRef.value.publication.title,
        }
      );
      await store.dispatch("notifications/FireNotification", {
        userId: rentRef.value.user,
        fromUserId: rentRef.value.publication.user,
        title: t("rent.checklist.notifications.paymentRequired.title"),
        message: messageNotification,
        avatar: {
          publicationID: rentRef.value.publication.id,
          publicationUser: rentRef.value.publication.user,
          defaultImage: rentRef.value.publication.orderPhotos[0],
        },
        linkTitle: t("rent.checklist.notifications.paymentRequired.linkTitle"),
        targetRoute: {
          name: "rent",
          params: [
            {
              key: "rentId",
              value: rentRef.value.id,
            },
            {
              key: "publicationId",
              value: rentRef.value.publication.id,
            },
          ],
        },
      });
    };

    const sendNotificationsAskingForReviews = async () => {
      await store.dispatch("notifications/FireNotification", {
        fromUserId: rentRef.value.user,
        userId: rentRef.value.user,
        title: t("rent.checklist.notifications.requestReview.title"),
        message: t("rent.checklist.notifications.requestReview.content", {
          publicationTitle: rentRef.value.publication.title,
        }),
        avatar: {
          publicationID: rentRef.value.publication.id,
          publicationUser: rentRef.value.publication.user,
          defaultImage: rentRef.value.publication.orderPhotos[0],
        },
        linkTitle: t("rent.checklist.notifications.requestReview.linkTitle"),
        targetRoute: {
          name: "review",
          params: [
            {
              key: "rentId",
              value: rentRef.value.id,
            },
          ],
        },
      });

      await store.dispatch("notifications/FireNotification", {
        fromUserId: rentRef.value.publication.user,
        userId: rentRef.value.publication.user,
        title: t("rent.checklist.notifications.requestReview.title"),
        message: t("rent.checklist.notifications.requestReview.content", {
          publicationTitle: rentRef.value.publication.title,
        }),
        avatar: {
          publicationID: rentRef.value.publication.id,
          publicationUser: rentRef.value.publication.user,
          defaultImage: rentRef.value.publication.orderPhotos[0],
        },
        linkTitle: t("rent.checklist.notifications.requestReview.linkTitle"),
        targetRoute: {
          name: "review",
          params: [
            {
              key: "rentId",
              value: rentRef.value.id,
            },
          ],
        },
      });
    };

    const checkIfCanDelivery = async () => {
      // Se revisa si la fecha de inicio del rent es la fecha actual
      if (
        differenceInCalendarDays(new Date(), rentRef.value.requestedRangeOfDates.start) <
        0
      ) {
        dialog.warning({
          title: t("rent.checklist.dialogs.tooEarly.title"),
          closable: false,
          maskClosable: false,
          content: t("rent.checklist.dialogs.tooEarly.title", {
            date: formatDates(rentRef.value.requestedRangeOfDates.start),
          }),
          positiveText: t("rent.checklist.dialogs.tooEarly.positiveButton.text"),
          onPositiveClick: () => {
            router.push({ name: "my-rents" });
          },
        });
      }

      // Se revisa si la publicacion no tiene otro rent en proceso
      if (
        await store.dispatch("rent/isOtherRentInProcess", {
          rentId: rentRef.value.id,
          publicationId: rentRef.value.publication.id,
        })
      ) {
        dialog.warning({
          title: t("rent.checklist.dialogs.otherRentInProcess.title"),
          closable: false,
          maskClosable: false,
          content: t("rent.checklist.dialogs.otherRentInProcess.content"),
          positiveText: t(
            "rent.checklist.dialogs.otherRentInProcess.positiveButton.text"
          ),
          onPositiveClick: () => {
            router.push({ name: "my-rents" });
          },
        });
      }

      // Se revisa si el rent esta atrasado y ya paso la fecha de devolucion
      if (
        differenceInCalendarDays(new Date(), rentRef.value.requestedRangeOfDates.end) > 0
      ) {
        const d = dialog.warning({
          title: t("rent.checklist.dialogs.tooLate.title"),
          closable: false,
          maskClosable: false,
          content: t("rent.checklist.dialogs.tooLate.content"),
          positiveText: t("rent.checklist.dialogs.tooLate.positiveButton.text"),
          onPositiveClick: () => {
            d.loading = true;
            return new Promise((resolve) => {
              console.debug("Finalizando rent");
              store.dispatch("app/lockUI");
              Promise.all([
                store.dispatch("maintainer_rents/setRentFinished"),
                store.dispatch("notifications/FireNotification", {
                  userId: rentRef.value.user,
                  fromUserId: rentRef.value.publication.user,
                  title: t("rent.checklist.notifications.rentFinished.title"),
                  message: t("rent.checklist.notifications.rentFinished.content", {
                    publicationTitle: rentRef.value.publication.title,
                  }),
                  avatar: {
                    publicationID: rentRef.value.publication.id,
                    publicationUser: rentRef.value.publication.user,
                    defaultImage: rentRef.value.publication.orderPhotos[0],
                  },
                  targetRoute: {
                    name: "view-rent-detail",
                    params: [
                      {
                        key: "id",
                        value: rentRef.value.id,
                      },
                    ],
                  },
                }),
                sendNotificationsAskingForReviews(),
              ])
                .then(() => {
                  console.debug("Redirecting to my rents");
                  router.push({ name: "my-rents" });
                })
                .catch((error) => {
                  console.log(error);
                  message.error(
                    t("rent.checklist.messageds.errorUpdatingStatus", {
                      status: rentRef.value.state.toLowerCase().replaceAll("_", " "),
                    })
                  );
                  router.push({
                    name: "view-rent-detail",
                    params: { id: rentRef.value.id },
                  });
                })
                .then(resolve)
                .finally(() => store.dispatch("app/unlockUI"));
            });
          },
        });
      }

      // Se revisa si todos los pagos estan procesados
      const amountOfPaymentsProcessed = parseFloat(
        rentRef.value.paymentsInfo
          .filter((payment) =>
            ["AUTHORIZED", "SUBMITTED_FOR_SETTLEMENT", "SETTLED"].includes(payment.status)
          )
          .reduce((partial, payment) => partial.add(new Big(payment.amount)), new Big(0))
          .getValue()
      );
      console.debug("amountOfPaymentsProcessed", amountOfPaymentsProcessed);
      console.debug("rentRef.value.totalCost", rentRef.value.totalCost);
      if (amountOfPaymentsProcessed !== rentRef.value.totalCost) {
        dialog.warning({
          title: t("rent.checklist.dialogs.paymentIssues.title"),
          closable: false,
          maskClosable: false,
          content: t("rent.checklist.dialogs.paymentIssues.content"),
          positiveText: t("rent.checklist.dialogs.paymentIssues.positiveButton.text"),
          onPositiveClick: () => {
            router.push({ name: "my-rents" });
          },
        });
      }
    };

    onMounted(async () => {
      //If is delivery and the current user is the owner, check if the rent must start today and is out of date
      if (rentRef.value.user !== currentUser) {
        if (props.checklistType === "DELIVERY") {
          await checkIfCanDelivery();
        }
      }

      initChecklist();
    });

    const getFinishMessage = () => {
      daysExecuted.value = rentRef.value.executedRangeOfDates
        ? differenceInCalendarDays(new Date(), rentRef.value.executedRangeOfDates.start)
        : 0;

      daysLeft.value =
        quantityDaysInRange(new Date(), rentRef.value.requestedRangeOfDates.end) - 1;

      delayedDays.value = differenceInCalendarDays(
        rentRef.value.executedRangeOfDates?.end || new Date(),
        rentRef.value.requestedRangeOfDates.end
      );
      console.debug("daysExecuted", daysExecuted.value);
      console.debug("daysLeft", daysLeft.value);
      console.debug("delayedDays", delayedDays.value);
      const content = [];
      content.push(h("br", {}));
      content.push(
        h(
          "div",
          {},
          t("rent.checklist.dialogs.startFinishConfirmation.content", {
            action:
              props.checklistType === "DELIVERY"
                ? t("commons.actions.start").toLowerCase()
                : t("commons.actions.finish").toLowerCase(),
          })
        )
      );

      if (props.checklistType === "RETURN") {
        if (rentRef.value.requestedDays > 2 && daysExecuted.value <= 1) {
          content.push(h("br", {}, ""));
          content.push(
            h(NAlert, { showIcon: false, type: "warning" }, () => {
              return t(
                "rent.checklist.dialogs.startFinishConfirmation.extraContentForRentThatJustStarted"
              );
            })
          );
        } else if (daysLeft.value > 0) {
          content.push(h("br", {}, ""));
          content.push(
            h(NAlert, { showIcon: false, type: "info" }, () => {
              return t(
                "rent.checklist.dialogs.startFinishConfirmation.extraContentForRentWithRemainingDays",
                {
                  qaytDays: daysLeft.value,
                  labelDays: t("commons.labels.day", daysLeft.value),
                }
              );
            })
          );
        } else if (delayedDays.value > 0) {
          content.push(h("br", {}, ""));
          content.push(
            h(
              DelayedMessageConfirmation,
              {
                "onUpdate:chargeExtraDays": (value) => {
                  console.debug("onUpdate:chargeExtraDays", value);
                  chargeExtraDays.value = value;
                },
                chargeExtraDays: chargeExtraDays.value,
                delayedDays: delayedDays.value,
              },
              ""
            )
          );
        }
      }

      content.push(h("br", {}));
      content.push(
        h("div", {}, t("rent.checklist.dialogs.startFinishConfirmation.confirmationText"))
      );
      return h("div", {}, content);
    };

    const sendNotification = async () => {
      let messageNotification = `${t(
        "rent.checklist.notifications.rentStartedFinished.content",
        {
          publicationTitle: rentRef.value.publication.title,
          action:
            props.checklistType === "DELIVERY"
              ? t("commons.actions.started").toLowerCase()
              : t("commons.actions.finished").toLowerCase(),
        }
      )}\n\n`;

      modelRef.value.checklist.forEach(
        (item) => (messageNotification += `\t\t * ${item.name}: ${item.value}\n`)
      );

      await store.dispatch("notifications/FireNotification", {
        userId: rentRef.value.user,
        fromUserId: rentRef.value.publication.user,
        title: t("rent.checklist.notifications.rentStartedFinished.title", {
          publicationTitle: rentRef.value.publication.title,
          action:
            props.checklistType === "DELIVERY"
              ? t("commons.actions.started").toLowerCase()
              : t("commons.actions.finished").toLowerCase(),
        }),
        message: messageNotification,
        avatar: {
          publicationID: rentRef.value.publication.id,
          publicationUser: rentRef.value.publication.user,
          defaultImage: rentRef.value.publication.orderPhotos[0],
        },
        targetRoute: {
          name: "view-rent-detail",
          params: [
            {
              key: "id",
              value: rentRef.value.id,
            },
          ],
        },
      });
    };

    const finish = async () => {
      console.debug("finish - started");
      if (props.checklistType === "DELIVERY") {
        await capturePaymentTransactionsAndChargeDeposit();
        message.success(t("rent.checklist.messages.rentStarted"));
      } else {
        //Se inicializa issue a generar en caso de haber danios a la herramienta
        const issue = modelRef.value.checklist.find(
          (checklistItem) => checklistItem.ref === 1
        ).value
          ? {
              subject: t("rent.checklist.others.issueSubjectWhenDamagesNoticed"),
              description: modelRef.value.checklist.find(
                (checklistItem) => checklistItem.ref === 2
              ).value,
              datetime: formatDates(new Date()),
              state: "OPEN",
              rentId: rentRef.value.id,
              relatedUsers: rentRef.value.relatedUsers,
              snapshotRentState: "RETURNED",
            }
          : null;

        //En caso de no haber danios, se limpia el campo de detalle de danios para evitar guardar informacion inconsistente
        if (issue === null) {
          modelRef.value.checklist.find(
            (checklistItem) => checklistItem.ref === 2
          ).value = null;
        }

        if (delayedDays.value > 0 && chargeExtraDays.value) {
          await store.dispatch("maintainer_rents/setRentPendingForPaymentAfterReturn", {
            checklist: modelRef.value.checklist,
            chargeExtraDays: chargeExtraDays.value,
          });
          await sendPaymentRequestForExtraDays();
          message.success(t("rent.checklist.messages.rentFinishedWithPaymentRequested"));
        } else if (rentRef.value.requestedDays > 2 && daysExecuted.value <= 1) {
          await executePaymentReverse();
          message.success(t("rent.checklist.messages.rentFinished"));
        } else {
          await store.dispatch("maintainer_rents/setRentReturned", {
            checklist: modelRef.value.checklist,
            chargeExtraDays: chargeExtraDays.value,
          });
          await sendNotification();
          await sendNotificationsAskingForReviews();
          message.success(t("rent.checklist.messages.rentFinished"));
        }

        if (issue) {
          store
            .dispatch("maintainer_rents/createIssue", issue)
            .then(() => {
              message.info(t("rent.checklist.messages.issueCreated"));
            })
            .catch((error) => {
              console.log(error);
              message.error(t("rent.checklist.messages.errorCreatingIssue"));
              throw error;
            });
        }
      }
      console.debug("finish - end");
    };

    const capturePaymentTransactionsAndChargeDeposit = async () => {
      //Se capturan los pagos que esten holdeados
      await store.dispatch(
        "maintainer_rents/setDeliveryCheckList",
        modelRef.value.checklist
      );

      //Se verifica el resultado de captura y se detiene el proceso si es que ha fallado
      if (rentRef.value.state !== "IN_PROCESS") {
        throw new Error(
          t("rent.checklist.messages.errorCapturingPayment", {
            state: rentRef.value.status,
          })
        );
      }

      if (rentRef.value.depositTransaction?.status !== "AUTHORIZED") {
        //De todos los pagos tomamos el inicial capturado para usar ese metodo de pago en el deposito
        const paymentInfo = rentRef.value.paymentsInfo.find(
          (paymentInfo) =>
            paymentInfo.type === "INITIAL" &&
            paymentInfo.status === "SUBMITTED_FOR_SETTLEMENT"
        );

        // Se ejecuta la transaccion hold de deposito
        try {
          const response = await store.dispatch("payments/Process", {
            rent: rentRef.value,
            publication: rentRef.value.publication,
            amount: rentRef.value.deposit,
            paymentType: "FOR_DEPOSIT",
            mode: "HOLD",
            paymentMethod: {
              details: {
                bin: paymentInfo.bin,
                cardType: paymentInfo.cardType,
                lastFour: paymentInfo.lastFour,
              },
            },
          });
          console.log(response);
        } catch (error) {
          message.error(t("rent.checklist.messages.errorHoldingDeposit"));
          await store.dispatch("maintainer_rents/setRentPendingForDeposit");
          store.dispatch("notifications/FireNotification", {
            userId: rentRef.value.user,
            fromUserId: rentRef.value.publication.user,
            title: t("rent.checklist.notifications.depositIssue.title"),
            message: t("rent.checklist.notifications.depositIssue.content"),
            avatar: {
              publicationID: rentRef.value.publication.id,
              publicationUser: rentRef.value.publication.user,
              defaultImage: rentRef.value.publication.orderPhotos[0],
            },
            linkTitle: t("rent.checklist.notifications.depositIssue.linkTitle"),
            targetRoute: {
              name: "rent",
              params: [
                {
                  key: "rentId",
                  value: rentRef.value.id,
                },
                {
                  key: "publicationId",
                  value: rentRef.value.publication.id,
                },
              ],
            },
          });
          throw error;
        }
      }
    };

    return {
      t,
      chargeExtraDays,
      rent: rentRef,
      model: modelRef,
      form: formRef,
      isLoading: isLoadingRef,
      current: currentRef,
      currentStatus: currentStatusRef,
      totalSteps: totalStepsRef,
      next: () => {
        formRef.value.validate((errors) => {
          if (!errors) {
            isLoadingRef.value = true;
            if (currentRef.value == totalStepsRef.value) {
              modelRef.value.state = "ACTIVE";
              const d = dialog.warning({
                title: t("rent.checklist.dialogs.startFinishConfirmation.title", {
                  action:
                    props.checklistType === "DELIVERY"
                      ? t("commons.actions.start").toLowerCase()
                      : t("commons.actions.finish").toLowerCase(),
                }),
                content: getFinishMessage,
                positiveText: t("commons.confirmation.yes"),
                negativeText: t("commons.confirmation.no"),
                onPositiveClick: () => {
                  d.loading = true;
                  d.closable = false;
                  d.maskClosable = false;
                  return new Promise((resolve) => {
                    store.dispatch("app/lockUI");
                    finish()
                      .then(() => {
                        console.debug("Redirecting to my rents");
                        router.push({ name: "my-rents" });
                      })
                      .catch((error) => {
                        console.log(error);
                        message.error(
                          t("rent.checklist.messageds.errorUpdatingStatus", {
                            status: rentRef.value.state
                              .toLowerCase()
                              .replaceAll("_", " "),
                          })
                        );
                        router.push({
                          name: "view-rent-detail",
                          params: { id: rentRef.value.id },
                        });
                      })
                      .then(resolve)
                      .finally(() => store.dispatch("app/unlockUI"));
                  });
                },
              });
            } else {
              if (currentRef.value === null || currentRef.value >= totalStepsRef.value) {
                currentRef.value = 1;
              } else {
                currentRef.value++;
              }
            }
            isLoadingRef.value = false;
          } else {
            console.error(errors);
          }
        });
      },
      prev: () => {
        if (currentRef.value === 1) currentRef.value = totalStepsRef.value;
        else currentRef.value--;
      },
      filesBucket: computed(() => {
        return `rent/${rentRef.value?.id}/checklist/${props.checklistType}`;
      }),
    };
  },
});
</script>
