<template>
  <n-alert :showIcon="false" type="warning">
    <n-space vertical
      ><div>
        {{
          t("rent.checklist.dialogs.returnDelayed.content", {
            qtyDays: delayedDays,
            daysLabel: t("commons.labels.day", delayedDays).toLowerCase(),
          })
        }}
      </div>
      <div>
        <n-radio
          :checked="checkedValue === 'yes'"
          value="yes"
          name="basic-demo"
          @change="handleChange"
        >
          {{ t("commons.confirmation.yes") }}
        </n-radio>
        <n-radio
          :checked="checkedValue === 'no'"
          value="no"
          name="basic-demo"
          @change="handleChange"
        >
          {{ t("commons.confirmation.no") }}
        </n-radio>
      </div></n-space
    >
  </n-alert>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { NRadio, NAlert, NSpace } from "naive-ui";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "DelayedMessageConfirmation",
  components: { NRadio, NAlert, NSpace },
  props: {
    chargeExtraDays: {
      type: Boolean,
      required: true,
    },
    delayedDays: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:chargeExtraDays"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const checkedValueRef = ref(props.chargeExtraDays ? "yes" : "no");
    watch(checkedValueRef, (newValue) => {
      emit("update:chargeExtraDays", newValue === "yes");
    });
    return {
      t,
      checkedValue: checkedValueRef,
      handleChange(e) {
        checkedValueRef.value = e.target.value;
      },
    };
  },
});
</script>
