<template>
  <n-h1>{{ title }}</n-h1>
  <n-space justify="end" class="pb-4" :class="isMobile ? '' : 'pr-4'"
    ><n-button type="primary" @click="addingNew = true"
      ><n-icon> <plus-icon /> </n-icon
      >{{
        t("commons.actions.add", { referece: t("commons.labels.field", 1) })
      }}</n-button
    ></n-space
  >

  <n-form-item
    v-for="(field, index) in checkListFields"
    :key="field.name"
    :label="field.hidden ? '' : field.name"
    ignore-path-change
    :path="`checklist[${index}].value`"
    :rule="
      field.hidden || field.type == 'BOOLEAN'
        ? { required: false }
        : field.type === 'NUMBER'
        ? { ...dynamicRule, type: 'number' }
        : dynamicRule
    "
  >
    <n-input
      v-if="field.type == 'STRING'"
      v-show="!field.hidden"
      :placeholder="field.name"
      @keydown.enter.prevent
      v-model:value="field.value"
    />
    <n-input-number
      style="width: 100%"
      v-if="field.type == 'NUMBER'"
      v-show="!field.hidden"
      :placeholder="field.name"
      @keydown.enter.prevent
      v-model:value="field.value"
    />
    <n-switch
      style="width: 100%"
      v-if="field.type == 'BOOLEAN'"
      v-show="!field.hidden"
      v-model:value="field.value"
    /><n-button
      :disabled="field.ref !== null"
      v-show="!field.hidden"
      @click="deleteField(field)"
      strong
      secondary
      circle
      class="ml-2"
    >
      <template #icon>
        <n-icon><trash-icon /></n-icon>
      </template>
    </n-button>
  </n-form-item>

  <n-modal
    v-model:show="addingNew"
    preset="dialog"
    :title="t('rent.checklist.addNewField.form.title')"
    :show-icon="false"
  >
    <n-form
      :style="{
        maxWidth: '640px',
      }"
      :model="formNewField"
      ref="modalForm"
      ><n-form-item
        :label="t('rent.checklist.addNewField.form.type.label')"
        ignore-path-change
        path="type"
        style="min-width: 100%"
        :rule="dynamicRule"
      >
        <n-select
          :consistent-menu-width="false"
          :options="optionsType"
          v-model:value="formNewField.type"
        /> </n-form-item
      ><n-form-item
        ignore-path-change
        path="name"
        :rule="dynamicRule"
        style="width: 100%"
        :label="t('rent.checklist.addNewField.form.name.label')"
        :validation-status="inputValidationStatus"
        :feedback="inputFeedback"
      >
        <n-input
          :placeholder="t('rent.checklist.addNewField.form.name.placeholder')"
          @keydown.enter.prevent
          v-model:value="formNewField.name"
        /> </n-form-item
    ></n-form>
    <template #action>
      <div>
        <n-button
          @click="addField()"
          type="primary"
          :disabled="
            formNewField.type === null ||
            formNewField.name === null ||
            formNewField.name?.trim() === '' ||
            fieldDuplicated
          "
          >Add</n-button
        >
      </div>
    </template>
  </n-modal>
</template>

<script>
import {
  NFormItem,
  NInput,
  NInputNumber,
  NSwitch,
  NForm,
  NH1,
  NButton,
  NSpace,
  NIcon,
  NModal,
  NSelect,
} from "naive-ui";
import { useStore } from "vuex";
import { ref, watch, computed } from "vue";
import responsive from "@/mixins/responsive";
import { titleCase } from "title-case";
import { Plus as PlusIcon, Trash as TrashIcon } from "@vicons/tabler";
import { useI18n } from "vue-i18n";

export default {
  name: "ChecklistForm",
  components: {
    NFormItem,
    NInput,
    NInputNumber,
    NSwitch,
    NForm,
    NH1,
    NButton,
    NSpace,
    PlusIcon,
    NIcon,
    TrashIcon,
    NModal,
    NSelect,
  },
  props: {
    modelValue: Object,
    checklistType: String,
  },
  mixins: [responsive],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const checkListFieldsRef = ref(props.modelValue);
    const modalFormRef = ref(null);
    watch(checkListFieldsRef, () => {
      emit("update:modelValue", checkListFieldsRef.value);
    });

    const onNewCheckList = () => {
      return {
        id: 0,
        name: "",
        type: "STRING",
      };
    };

    const optionsType = computed(() => store.getters["shared/options_inputType"]);

    const dynamicRule = {
      required: true,
      trigger: ["blur", "change"],
      message: "Please provide a value",
    };
    const formNewField = ref({
      type: null,
      name: null,
    });
    const addingNew = ref(false);

    const fieldDuplicatedRef = computed(() => {
      if (formNewField.value.name && formNewField.value.name.length > 0) {
        return (
          checkListFieldsRef.value.filter(
            (currentField) =>
              currentField.name.trim().toLowerCase() ===
              formNewField.value.name.trim().toLowerCase()
          ).length > 0
        );
      } else {
        return false;
      }
    });

    return {
      t,
      checkListFields: checkListFieldsRef,
      onNewCheckList,
      optionsType,
      title: computed(() => titleCase(`${props.checklistType.toLowerCase()} checklist`)),
      dynamicRule,
      deleteField: (field) => {
        if (!field.ref) {
          checkListFieldsRef.value = checkListFieldsRef.value.filter(
            (currentField) => currentField.name != field.name
          );
        }
      },
      addField: () => {
        checkListFieldsRef.value.push(formNewField.value);
        formNewField.value = {
          type: null,
          name: null,
        };
        addingNew.value = false;
      },
      fieldDuplicated: fieldDuplicatedRef,
      addingNew,
      formNewField,
      inputValidationStatus: computed(() => {
        return fieldDuplicatedRef.value ? "error" : undefined;
      }),
      inputFeedback: computed(() => {
        return fieldDuplicatedRef.value ? "Field duplicated" : undefined;
      }),
      modalForm: modalFormRef,
    };
  },
};
</script>
