<template>
  <n-tabs type="segment" default-value="Return Checklist" class="pr-2">
    <n-tab-pane
      name="Delivery Checklist"
      :tab="`${commons.labels.delivery} ${commons.labels.checlist}`"
      display-directive="show"
    >
      <ChecklistResume
        v-model="rent.deliveryCheckList"
        checklistType="DELIVERY"
        :bucket="`${filesBucket}/DELIVERY`"
        :ownerId="rent.publication.user"
      />
      <n-button
        v-if="rent?.state != 'IN_PROCESS'"
        type="primary"
        size="large"
        class="my-3"
        @click="onBack()"
        >Back</n-button
      >
    </n-tab-pane>
    <n-tab-pane
      name="Return Checklist"
      :tab="`${commons.labels.return} ${commons.labels.checlist}`"
      display-directive="show"
    >
      <div v-if="rent?.state != 'IN_PROCESS'" class="p-4">
        <ChecklistResume
          v-model="rent.returnCheckList"
          :checklistType="checklistType"
          :bucket="`${filesBucket}/RETURN`"
          :ownerId="rent.publication.user"
        />
        <n-button type="primary" size="large" class="my-3" @click="onBack()"
          >t("commons.actions.back")</n-button
        >
      </div>
      <CheckListStepper v-else :checklistType="checklistType" />
    </n-tab-pane>
  </n-tabs>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { NButton, NTabs, NTabPane } from "naive-ui";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import ChecklistResume from "@/components/rent/Checklist/ChecklistResume.vue";
import { useRouter } from "vue-router";
import CheckListStepper from "@/components/rent/Checklist/Stepper.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "ReturnView",
  components: {
    ChecklistResume,
    NButton,
    CheckListStepper,
    NTabs,
    NTabPane,
  },
  mixins: [responsive],
  props: {
    checklistType: {
      type: String,
      required: true,
      validator(value) {
        return ["DELIVERY", "RETURN"].includes(value);
      },
    },
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const rentRef = computed(() => store.state.maintainer_rents.detail);
    onMounted(() => {
      if (
        rentRef.value.user === store.getters["auth/user_id"] &&
        rentRef.value.state === "IN_PROCESS"
      ) {
        router.push({
          name: "checklist",
          params: { rentId: rentRef.value?.id, checklistType: "DELIVERY" },
        });
      }
    });
    return {
      t,
      rent: rentRef,
      filesBucket: computed(() => {
        return `rent/${rentRef.value?.id}/checklist`;
      }),
      onBack() {
        router.go(-1);
      },
    };
  },
});
</script>
