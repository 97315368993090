<template>
  <div v-if="rent?.state != 'PENDING'" class="p-4">
    <ChecklistResume
      v-if="rent.deliveryCheckList"
      v-model="rent.deliveryCheckList"
      :checklistType="checklistType"
      :bucket="filesBucket"
      :ownerId="rent.publication.user"
    />
    <n-button type="primary" size="large" class="my-3" @click="onBack()">{{
      t("commons.actions.back")
    }}</n-button>
  </div>
  <CheckListStepper v-else :checklistType="checklistType" />
</template>

<script>
import { defineComponent, computed } from "vue";
import { NButton } from "naive-ui";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import ChecklistResume from "@/components/rent/Checklist/ChecklistResume.vue";
import { useRouter } from "vue-router";
import CheckListStepper from "@/components/rent/Checklist/Stepper.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "DeliveryView",
  components: {
    ChecklistResume,
    NButton,
    CheckListStepper,
  },
  mixins: [responsive],
  props: {
    checklistType: {
      type: String,
      required: true,
      validator(value) {
        return ["DELIVERY", "RETURN"].includes(value);
      },
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const rentRef = computed(() => store.state.maintainer_rents.detail);

    return {
      t,
      rent: rentRef,
      filesBucket: computed(() => {
        return `rent/${rentRef.value?.id}/checklist/${props.checklistType}`;
      }),
      onBack() {
        router.go(-1);
      },
    };
  },
});
</script>
