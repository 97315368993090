<template>
  <div>
    <n-form-item path="files">
      <n-upload
        v-model:file-list="listFiles"
        list-type="image-card"
        @remove="removeFile"
        style="text-align: left"
        @before-upload="beforeUpload"
        show-download-button
        @download="handleDownload"
      >
      </n-upload>
    </n-form-item>
  </div>
  <n-modal
    v-model:show="showFileSupportedInfo"
    preset="dialog"
    :title="t('commons.form.validation.fileTypeNotAllowed')"
    type="error"
  >
    {{ t("rent.checklist.uploadFile.diaglos.fileTypeNotAllowed.content") }}:
    <br />
    <ul>
      <li v-for="fileType in filesTypeAllowed" :key="fileType">{{ fileType }}</li>
    </ul>
  </n-modal>
</template>
<script>
import { NFormItem, NUpload, useMessage, NModal } from "naive-ui";
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Storage } from "aws-amplify";
import {
  getFileNameFromKey,
  getExtensionFromFileName,
  downloadBlob,
} from "@/shared/utils";
import mime from "mime";
import { compareDesc } from "date-fns";
import { useI18n } from "vue-i18n";
export default {
  name: "FileUploader",
  components: {
    NFormItem,
    NModal,
    NUpload,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    bucket: {
      type: String,
      required: true,
    },
    filesTypeAllowed: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "update:loading"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const message = useMessage();
    const filesRef = ref(props.modelValue);
    const listFilesRef = ref([]);
    const isLoading = computed(() => {
      return (
        listFilesRef.value?.filter(
          (file) => file.status == "pending" || file.status == "uploading"
        ).length > 0
      );
    });
    const showFileSupportedInfoRef = ref(false);
    watch(filesRef, (newFilesRef) => emit("update:modelValue", newFilesRef));
    watch(listFilesRef, () => uploadFiles());
    watch(isLoading, (isLoading) => emit("update:loading", isLoading));

    const uploadFiles = async () => {
      listFilesRef.value
        ?.filter((item) => item.status == "pending")
        .forEach(async (element) => {
          const file = element.file;
          element.status = "uploading";
          await Storage.put(`${props.bucket}/${file.name}`, file, {
            resumable: true,
            level: "protected",
            identityId: store.getters["auth/user_id"],
            contentType: file.type,
            completeCallback: async () => {
              element.percentage = 100;
              element.status = "finished";
              element.id = `${props.bucket}/${file.name}`;
              element.url = file.type.startsWith("image")
                ? await getUrlFile(`${props.bucket}/${file.name}`)
                : null;
              element.type = file.type;
              element.lastModified = new Date();
              filesRef.value.push(file.name);
            },
            progressCallback: (progress) => {
              element.percentage = (progress.loaded / progress.total) * 100;
            },
            errorCallback: () => {
              element.status = "error";
            },
          });
        });
    };

    onMounted(async () => {
      store.dispatch("app/lockUI");
      const files = await Storage.list(`${props.bucket}`, {
        level: "protected",
        identityId: store.getters["auth/user_id"],
      });
      if (files.length > 0) {
        files.sort((a, b) => {
          return compareDesc(b.lastModified, a.lastModified);
        });

        for (const file of files) {
          const fileType = mime.getType(getExtensionFromFileName(file.key));
          listFilesRef.value.push({
            id: file.key,
            name: getFileNameFromKey(file.key),
            status: "finished",
            url: fileType.startsWith("image") ? await getUrlFile(file.key) : null,
            type: fileType,
            lastModified: file.lastModified,
          });
          filesRef.value.push(getFileNameFromKey(file.key));
        }
      }

      store.dispatch("app/unlockUI");
    });

    const getUrlFile = async (fileId) => {
      return Storage.get(fileId, {
        level: "protected",
        identityId: store.getters["auth/user_id"],
      });
    };

    return {
      t,
      listFiles: listFilesRef,
      beforeUpload: async ({ file }) => {
        if (props.filesTypeAllowed.length === 0) {
          return true;
        }
        console.debug(`Checking if filetype ${file.type} is allowed`);
        if (!props.filesTypeAllowed.includes(file.type)) {
          showFileSupportedInfoRef.value = true;
          return false;
        }
        return true;
      },
      removeFile: ({ file }) => {
        store.dispatch("app/lockUI");
        Storage.remove(file.id, {
          level: "protected",
          identityId: store.getters["auth/user_id"],
        })
          .then(() => {
            filesRef.value = filesRef.value.filter((file) => file != file.name);
            return true;
          })
          .catch((error) => {
            console.error(error);
            message.error(t("rent.checklist.uploadFile.messages.errorDeletingFile"));
            return false;
          })
          .finally(() => {
            store.dispatch("app/unlockUI");
          });
      },
      handleDownload: async (file) => {
        store.dispatch("app/lockUI");
        const result = await Storage.get(file.id, {
          download: true,
          level: "protected",
          identityId: store.getters["auth/user_id"],
        });
        downloadBlob(result.Body, file.name);
        store.dispatch("app/unlockUI");
      },
      showFileSupportedInfo: showFileSupportedInfoRef,
    };
  },
};
</script>
<style scoped>
.descImgWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: block;
}
</style>
